exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-invitations-index-js": () => import("./../../../src/pages/admin/invitations/index.js" /* webpackChunkName: "component---src-pages-admin-invitations-index-js" */),
  "component---src-pages-admin-partners-js": () => import("./../../../src/pages/admin/partners.js" /* webpackChunkName: "component---src-pages-admin-partners-js" */),
  "component---src-pages-foryou-index-js": () => import("./../../../src/pages/foryou/index.js" /* webpackChunkName: "component---src-pages-foryou-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invitations-index-js": () => import("./../../../src/pages/invitations/index.js" /* webpackChunkName: "component---src-pages-invitations-index-js" */),
  "component---src-pages-partners-index-js": () => import("./../../../src/pages/partners/index.js" /* webpackChunkName: "component---src-pages-partners-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-v-1-js": () => import("./../../../src/pages/v1.js" /* webpackChunkName: "component---src-pages-v-1-js" */)
}

